import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

function IndexPage(props) {
  const router = useRouter()
  useEffect(() => {
    router.replace('/home')
  }, [router])
  return <div />
}

export default IndexPage
